var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"advisor-form"},[_c('Header',{}),_c('h2',{staticClass:"margin-top25"}),_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('form-wizard',{staticClass:"mb-3 pt-1",attrs:{"color":"#366ce3","title":_vm.$t('AdvisorForm.Register_advisor'),"subtitle":_vm.$t('AdvisorForm.Complete_your_account'),"shape":"square","finish-button-text":_vm.$t('common.Submit'),"next-button-text":_vm.$t('common.Next'),"back-button-text":_vm.$t('common.Previous')},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":_vm.$t('AdvisorForm.Advisor_Details'),"icon":"feather icon-file-text","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Advisor_Details")))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Ente_Advisor_Details"))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.Full_Name'),"label-for":"Fomraml_name"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Fomraml_name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('AdvisorForm.Full_Name')},model:{value:(_vm.createAdvisorProfile.name),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "name", $$v)},expression:"createAdvisorProfile.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"john.doe@email.com"},model:{value:(_vm.createAdvisorProfile.emailValue),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "emailValue", $$v)},expression:"createAdvisorProfile.emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.Phone_Number'),"label-for":"Phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"}},[_c('VuePhoneNumberInput',{attrs:{"color":"#ea5455","error-color":"#ea5455","translations":_vm.$store.state.locale.locale == 'ar'
                        ? _vm.translationsArabic
                        : _vm.translations},on:{"update":_vm.onUpdate},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),(!_vm.isValidNumber)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("common.phone_Invalid")))]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"owner_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"label-for":"owner_type"}},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Owner_Type"))+" ")]),_c('v-select',{attrs:{"id":"owner_type","options":_vm.$store.state.locale.locale == 'ar'
                        ? _vm.OwnerTypeOptionArabic
                        : _vm.OwnerTypeOption,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.createAdvisorProfile.ownerType),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "ownerType", $$v)},expression:"createAdvisorProfile.ownerType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),(_vm.createAdvisorProfile.ownerType.value == 'individual')?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.Gender'),"label-for":"Gender","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"Gender","options":_vm.$store.state.locale.locale == 'ar'
                        ? _vm.createAdvisorProfile.GenderOptionArabic
                        : _vm.createAdvisorProfile.GenderOption,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.createAdvisorProfile.selectedGender),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "selectedGender", $$v)},expression:"createAdvisorProfile.selectedGender"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1901573760)})],1):_vm._e()],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('AdvisorForm.Advisor_Info'),"icon":"feather icon-user","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Advisor_Info")))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Enter_Advisor_Info")))])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.Designation'),"label-for":"designation"}},[_c('validation-provider',{attrs:{"name":"designation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"designation","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('AdvisorForm.Designation')},model:{value:(_vm.createAdvisorProfile.designation),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "designation", $$v)},expression:"createAdvisorProfile.designation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Industry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.industries_service_belong'),"label-for":"Industry","state":errors.length > 0 ? false : null}},[_c('div',{attrs:{"id":"app"}},[_c('treeselect',{attrs:{"multiple":true,"placeholder":_vm.$t('common.Select'),"options":_vm.industries,"normalizer":_vm.$store.state.locale.locale == 'ar'
                          ? _vm.normalizerArabic
                          : _vm.normalizer},on:{"input":_vm.handeTreeSelect},model:{value:(_vm.createAdvisorProfile.selectedIndustry),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "selectedIndustry", $$v)},expression:"createAdvisorProfile.selectedIndustry"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"SkiSkillsIDlls","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.Your_Skills'),"label-for":"SkillsID","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"SkillsID","options":_vm.skills,"selectable":function () { return _vm.createAdvisorProfile.selectedSkills.length <
                        ("" + _vm.$maxSkillsUser); },"multiple":"","label":"text"},model:{value:(_vm.createAdvisorProfile.selectedSkills),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "selectedSkills", $$v)},expression:"createAdvisorProfile.selectedSkills"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.Country'),"label-for":"Country","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"Country","multiple":"","options":_vm.countries,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.createAdvisorProfile.selectedCountry),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "selectedCountry", $$v)},expression:"createAdvisorProfile.selectedCountry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.Brief_overview_yourself'),"label-for":"Highlights"}},[_c('validation-provider',{attrs:{"name":"Highlights","rules":("required|max:" + _vm.$maxHighlightsUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Highlights","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('AdvisorForm.Brief_overview_yourself')},model:{value:(_vm.createAdvisorProfile.Highlights),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "Highlights", $$v)},expression:"createAdvisorProfile.Highlights"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.description_yourself'),"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('AdvisorForm.description_yourself')},model:{value:(_vm.createAdvisorProfile.description),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "description", $$v)},expression:"createAdvisorProfile.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.description_Previous_Project'),"label-for":"previousProject"}},[_c('validation-provider',{attrs:{"name":"previousProject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"previousProject","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('AdvisorForm.description_Previous_Project')},model:{value:(_vm.createAdvisorProfile.previousProject),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "previousProject", $$v)},expression:"createAdvisorProfile.previousProject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('AdvisorForm.Advisor_Experience'),"icon":"feather icon-tag","before-change":_vm.validationFormAddress}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("AdvisorForm.About_you")))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Enter_Language_Experiences")))])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"","label-for":"language"}},[_c('validation-provider',{attrs:{"name":"language","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('form-repeater-basic',{attrs:{"items":_vm.items}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"","label-for":"Experience"}},[_c('validation-provider',{attrs:{"name":"Experience","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('Experience',{attrs:{"experiences":_vm.experiences}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('AdvisorForm.Social_Links'),"icon":"feather icon-link","before-change":_vm.validationFormSocial}},[_c('validation-observer',{ref:"socialRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Social_Links")))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Enter_Social_Links")))])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.Facebook'),"label-for":"facebook"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facebook","state":errors.length > 0 ? false : null,"placeholder":"https://facebook.com/abc"},model:{value:(_vm.createAdvisorProfile.facebookUrl),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "facebookUrl", $$v)},expression:"createAdvisorProfile.facebookUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.Twitter'),"label-for":"twitter"}},[_c('validation-provider',{attrs:{"name":"Twitter","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"twitter","state":errors.length > 0 ? false : null,"placeholder":"https://twitter.com/abc"},model:{value:(_vm.createAdvisorProfile.twitterUrl),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "twitterUrl", $$v)},expression:"createAdvisorProfile.twitterUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.LinkedIn'),"label-for":"linked-in"}},[_c('validation-provider',{attrs:{"name":"LinkedIn","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"linked-in","state":errors.length > 0 ? false : null,"placeholder":"https://linkedin.com/abc"},model:{value:(_vm.createAdvisorProfile.linkedinUrl),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "linkedinUrl", $$v)},expression:"createAdvisorProfile.linkedinUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AdvisorForm.Website'),"label-for":"Other"}},[_c('validation-provider',{attrs:{"name":"Other","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Other","state":errors.length > 0 ? false : null,"placeholder":"https://other.com/abc"},model:{value:(_vm.createAdvisorProfile.OtherUrl),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "OtherUrl", $$v)},expression:"createAdvisorProfile.OtherUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('AdvisorForm.Attachment'),"icon":"feather icon-file-plus","before-change":_vm.validationFormFiles}},[_c('validation-observer',{ref:"attachmentRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Attachment")))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Upload_your_files")))])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Personal Photo","rules":("required|size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{attrs:{"for":"PersonalPhoto"}},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Upload_Personal_Photo"))+" ")]),_c('b-form-file',{attrs:{"id":"PersonalPhoto","required":"","accept":"image/jpeg, image/png, image/gif","state":errors.length > 0 ? false : null,"drop-placeholder":"Drop file here...","multiple":false,"placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createAdvisorProfile.PersonalPhoto),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "PersonalPhoto", $$v)},expression:"createAdvisorProfile.PersonalPhoto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Advisor Prove","rules":("required|size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{attrs:{"for":"Advisor-Prove"}},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Advisor_Prove"))+" ")]),_c('b-form-file',{attrs:{"id":"Advisor-Prove","required":"","accept":"image/jpeg, image/png, image/gif","state":errors.length > 0 ? false : null,"drop-placeholder":"Drop file here...","multiple":false,"placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createAdvisorProfile.AdvisorProve),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "AdvisorProve", $$v)},expression:"createAdvisorProfile.AdvisorProve"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"certificates","rules":("size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{attrs:{"for":"Advisor-certificates"}},[_vm._v(_vm._s(_vm.$t("AdvisorForm.Upload_certificates"))+" ")]),_c('b-form-file',{attrs:{"id":"Advisor-certificates","accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","multiple":"","browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createAdvisorProfile.AdvisorCertificates),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "AdvisorCertificates", $$v)},expression:"createAdvisorProfile.AdvisorCertificates"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"relevant docouments","rules":("size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{attrs:{"for":"relevant-Docouments"}},[_vm._v(_vm._s(_vm.$t("AdvisorForm.relevant_Docouments"))+" ")]),_c('b-form-file',{attrs:{"id":"relevant-Docouments","accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","multiple":"","browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createAdvisorProfile.AdvisorRelevant),callback:function ($$v) {_vm.$set(_vm.createAdvisorProfile, "AdvisorRelevant", $$v)},expression:"createAdvisorProfile.AdvisorRelevant"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[(_vm.errorMessage)?_c('b-alert',{staticClass:"warning",staticStyle:{"color":"red"},attrs:{"show":"","variant":"warning p-1"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)],1)],1)],1)],1)],1),_c('Footer',{staticClass:"company-form-footer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }